<template>
  <b-container
    class="d-flex flex-column align-items-start justify-content-start px-3 pr-lg-5 pl-lg-0"
  >
    <!-- <template v-if="isAnalyticsLoading">
            <b-overlay :show="isAnalyticsLoading" no-wrap variant="white">
            </b-overlay>
          </template> -->

    <!-- analytics -->
    <!-- <div class="d-flex align-items-center justify-content-start shadow-sm border-1 insight-cards-row w-100 bg-light pl-4">
        <div class="mr-4">
            <h6 class="text-left text-truncate text-small font-weight-bold text-info">
                Paid last 12 months
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
                {{ analytics.amountPaidlast12months}}
            </h5>
        </div>
        <div class="">
            <h6 class="text-left text-truncate text-small font-weight-bold text-info">
                Total unpaid
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
                {{ analytics.amountTotalunpaid }}
            </h5>
        </div>
    </div> -->
    <b-row
      class="d-flex flex-row align-items-stretch justify-content-center w-100"
      no-gutters
    >
      <b-card
        no-body
        class="position-relative overflow-hidden shadow-sm border-1 insight-cards-row w-100 bg-light"
      >
        <template v-if="isAnalyticsLoading">
          <b-overlay :show="isAnalyticsLoading" no-wrap variant="white">
          </b-overlay>
        </template>
        <template>
          <b-row
            class="d-flex flex-row align-items-center justify-content-start"
            no-gutters
          >
            <!-- Paid last 12 months column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="12"
              sm="6"
              lg="3"
            >
              <h6
                class="text-left text-truncate text-small font-weight-bold text-main-green"
              >
                Paid last 12 months
              </h6>
              <h5 class="text-left font-secondary font-weight-bold text-dark">
                {{ currency }} {{ numberFormat(paid_last_12) }}
              </h5>
            </b-col>
            <!-- Total unpaid column -->

            <b-col
              class="d-flex flex-column align-items-start justify-content-center mt-3 mt-sm-0"
              cols="12"
              sm="6"
              lg="3"
            >
              <h6
                class="text-left text-truncate text-small font-weight-bold text-main-green"
              >
                Total unpaid
              </h6>
              <h5 class="text-left font-secondary font-weight-bold text-dark">
                {{ currency }} {{ numberFormat(total_unpaid) }}
              </h5>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-row>

    <h5 class="unpaid-title text-dark">All unpaid invoices</h5>

    <!-- table container row -->
    <b-row
      class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
      no-gutters
    >
      <!-- table row -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start  w-100 table-container-row"
        no-gutters
      >
        <b-table
          ref="questionsTable"
          :bordered="false"
          :borderless="true"
          :hover="true"
          selectable
          show-empty
          select-mode="single"
          class="table-element"
          thead-class="table-header font-secondary"
          tbody-class="table-body"
          :busy="isLoading"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="null"
          :items="invoiceItems"
        >
          <!-- table busy state -->
          <template #table-busy>
            <div class="text-center text-muted my-3">
              <b-spinner class="align-middle"></b-spinner>
              <span class="text-left mx-1">Loading...</span>
            </div>
          </template>
          <!-- table empty state -->
          <template #empty="scope">
            <b-row
              class="d-flex flex-row align-items-center justfiy-content-center py-5"
              no-gutters
            >
              <h6 class="text-center mb-0 mx-auto my-5">
                {{ scope.emptyText }}
              </h6>
            </b-row>
          </template>
          <!-- invoice Status  -->
          <template #cell(status)="data">
            <div class="d-flex align-items-center text-nowrap">
              <div
                :class="['ellipse', getStatusColor(data.value)]"
              ></div>
              <span class="main-text ml-2 text-capitalize">{{
                data.value
              }}</span>
            </div>
          </template>
          <!-- invoice Name -->
          <template #cell(title)="data">
            <span class="main-text text-nowrap"> {{ data.value }}</span>
          </template>
          <!-- invoice Due  -->
          <!-- <template #cell()="item">
                    <span class="main-text text-nowrap"> {{getPrettyDate(item.item.dueDate)}} </span>
                </template> -->
          <template #cell(dueDate)="data">
            <span
              class="main-text text-nowrap"
              :class="data.value < 0 && 'text-danger'"
            >
              {{ getPrettyDate(data.value) }}
            </span>
          </template>
          <!-- invoice Due Amount  -->
          <template #cell(amountDue)="data">
            <span class="main-text text-nowrap"
              >{{ currency }} {{ numberFormat(data.value) }}</span
            >
          </template>
          <!-- actions column -->
          <template #cell(actions)="data">
            <b-dropdown
              id="dropdown-left"
              text="Left align"
              toggle-class="shadow-sm ml-auto border border-prime-gray more-btn rounded-circle"
              no-caret
              right
            >
              <template #button-content>
                <!-- <b-button
                      variant="outline-dark"
                      v-b-tooltip.hover.left
                      title="View More Actions"
                      class="shadow-sm ml-auto border border-prime-gray more-btn"
                      pill
                    > -->
                <b-icon
                  icon="chevron-down"
                  aria-label="View More Actions"
                  class="mx-auto more-icon"
                  size="lg"
                  font-scale="1.2"
                ></b-icon>
                <!-- </b-button> -->
              </template>
              <b-dropdown-item-button @click="editInvoiceFn(data.value.id)"
                                      :disabled="getUserRole.role === 'User'"
                >Edit</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="viewInvoiceFn(data.value.id)"
                >View</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="createInvoice"
                                      :disabled="getUserRole.role === 'User'"
                >Create Invoice</b-dropdown-item-button
              >
              <b-dropdown-item-button
                button-class="text-danger"
                @click="openDeleteInvoiceModal(data.item, $event.target)"
                :disabled="getUserRole.role.includes('User')"
                >Delete</b-dropdown-item-button
              >
            </b-dropdown>
          </template>
        </b-table>
      </b-row>
      <!-- table controls -->
      <!-- safari fix (flex-shrink-0) -->
      <b-row
        class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
        no-gutters
      >
        <!-- pages per page control -->
        <b-col
          class="d-flex flex-column align-items-center align-items-sm-start align-items-md-start align-items-xl-start justify-content-center py-2 mx-5 mb-3 py-md-1"
          cols="12"
          sm="4"
          lg="4"
          xl="3"
        >
          <b-form-group
            label="Per page"
            label-cols="12"
            label-cols-sm="6"
            label-align="left"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0 w-100"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              class="per-page-select border-1 border-main-green py-0"
              :options="pageOptions"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- pagination controls -->
        <!-- <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    limit="4"
                    pills
                    class="d-none d-sm-flex table-pagination my-0"
                    page-class="custom-page-item"
                    ellipsis-class="custom-page-item"
                    first-class="custom-page-item"
                    last-class="custom-page-item"
                    next-class="custom-page-item"
                    prev-class="custom-page-item"
                >
                </b-pagination>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage" align="center"
                    size="sm" limit="5"
                    pills
                    class="d-flex d-sm-none table-pagination my-0"
                    page-class="custom-page-item"
                    ellipsis-class="custom-page-item"
                    first-class="custom-page-item"
                    last-class="custom-page-item"
                    next-class="custom-page-item"
                    prev-class="custom-page-item"
                >
                </b-pagination> -->
        <b-col
          class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="md"
            limit="4"
            pills
            class="table-pagination"
            page-class="custom-page-item"
            ellipsis-class="custom-page-item"
            first-class="custom-page-item"
            last-class="custom-page-item"
            next-class="custom-page-item"
            prev-class="custom-page-item"
          >
            <template #first-text>
              <b-icon
                class="text-main-green"
                icon="chevron-left"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
            <template #prev-text>
              <b-icon
                class="text-main-green"
                icon="chevron-double-left"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
            <template #next-text>
              <b-icon
                class="text-main-green"
                icon="chevron-double-right"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
            <template #last-text>
              <b-icon
                class="text-main-green"
                icon="chevron-right"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
      <DeleteInvoiceModal
        :isShow="isShowDeleteItemRow"
        :key="showDeleteInvoiceModalKey"
        :itemContent="this.invoiceId"
        @reloadFn="callInitFn"
        @onClose="onCloseDeleteInvoiceModal"
      />
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
//import DeleteCustomerModal from "@/components/Customers/modals/DeleteCustomerModal";
import { CustomerSummery, GetUnpaidInvoices } from "@/services/invoice.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import DeleteInvoiceModal from "@/components/Invoices/modals/DeleteInvoiceModal";
import { formatNumbers } from "@/util/formatNumbers.js";
import {mapGetters} from "vuex";

export default {
  name: "CustomerOverview",
  components: {
    DeleteInvoiceModal,
  },
  created() {
    // this.loadCurrentWorkspace();
    this.loadCustomerSum(this.$route.params.customerId);
    this.fetchUserUnpaidInvoices(this.$route.params.customerId);
  },
  data() {
    return {
      currency: localStorage.getItem("CURRENCY"),
      analytics: {
        amountPaidlast12months: "2000.00",
        amountTotalunpaid: "3000.00",
      },
      isShowDeleteItemRow: false,
      showDeleteCustomerModalKey: Number(new Date()) + 100,
      rowItem: null,
      rowIndex: null,
      filterDropDownShow: false,
      selectedRegion: {
        title: null,
        code: null,
        flagCode: null,
        languageCode: null,
      },
      isRegionFilter: false,
      searchTable: null,
      isLoading: false,
      invoiceItems: [],
      // table pagination controls
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,

      paid_last_12: 0.0,
      total_unpaid: 0.0,
      showDeleteInvoiceModalKey: Number(new Date()) + 100,
    };
  },
  // async mounted() {
  //     await this.initFn();
  // },
  methods: {
    numberFormat(val) {
      return formatNumbers(val);
    },
    async loadCustomerSum(customer) {
      let { data } = await CustomerSummery(customer);
      console.log("unpaid", data);
      this.total_unpaid = data.total_unpaid ? data.total_unpaid : 0.0;
      this.paid_last_12 = data.paid_last_12 ? data.paid_last_12 : 0.0;
    },
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async fetchUserUnpaidInvoices(customer_id) {
      try {
        this.isLoading = true;
        let { data } = await GetUnpaidInvoices(
          customer_id,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          undefined,
          true
          );
          console.log("🚀 ~ file: CustomerOverview.vue:427 ~ fetchUserUnpaidInvoices ~ data:", data)
        this.invoiceItems = data.results.results.map((x) => {
          // return {
          //     // invoiceId: x._id,
          //     statusDetails: {
          //         id: x._id,
          //         status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
          //         dueDate: x.due_date.split("T")[0],
          //     },
          //     dateDetails: {
          //         id: x._id,
          //         date: x.issued_date.split("T")[0],
          //     },
          //     numberDetails: {
          //         id: x._id,
          //         number: x.invoice_number,
          //     },
          //     customerDetails: {
          //         id: x._id,
          //         customerName: x.customer_name,
          //     },
          //     totalAmount: {
          //         id: x._id,
          //         totalAmount: x.total_amount,
          //     },
          //     amountDetails: {
          //         id: x._id,
          //         amountDue: x.amount_due,
          //         totalAmount: x.total_amount,
          //     },
          //     invoiceDetails: {
          //         id: x._id,
          //         title: x.title,
          //     },
          //     dueDate: x.due_date.split("T")[0],
          //     actions: {
          //         id: x._id,
          //         status: x.status,
          //         dueDate: x.due_date.split("T")[0],
          //     },

          return {
            status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
            title: 'Unpaid Invoice',
            dueDate: this.getDateDifference(x.due_date.split("T")[0]),
            amountDue: x.total_amount,
            actions: {
              id: x._id,
              status: x.status,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });
        this.isLoading = false;
        console.log("draft invoice items", this.invoiceItems);
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    getDateDifference(inputDate, dateToCompareWith = new Date()) {
      const date1 = new Date(inputDate);
      const date2 = new Date(dateToCompareWith);
      const diffTime = date1 - date2;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return diffDays;
    },
    getPrettyDate(dateDifference) {
      if (dateDifference < 0) {
        // past date
        if (dateDifference > -2) return "Yesterday";
        else return `Due ${Math.ceil(Math.abs(dateDifference))} days ago`;
      } else {
        // future date
        if (dateDifference < 1) return "Today";
        else if (dateDifference < 2) return "Tomorrow";
        else return `In ${Math.ceil(Math.abs(dateDifference))} days`;
      }
    },
    getStatusColor(status) {
     
     if (status === "Draft") {
       return "bg-gray-750 text-prime-gray";
     } else if (status === "Unsent") {
       return "bg-gray-750 text-prime-gray";
     } else if (status === "Sent") {
       return "bg-info";
     } else if (status === "Overdue") {
       return "bg-danger";
     } else if (status === "Paid") {
       return "bg-success";
     }
     else if (status === "Partial") {
       return "bg-warning";
     }
     return "bg-gray-750";
   },
    editInvoiceFn(id) {
      this.$router.push("/invoices/edit/" + id);
    },
    viewInvoiceFn(id) {
      this.$router.push("/invoices/" + id);
    },
    createInvoice() {
      this.$router.push("/invoices/create");
    },
    onCloseDeleteInvoiceModal() {
      this.isShowDeleteItemRow = false;
    },
    openDeleteInvoiceModal(item, button) {
      // this.$store.dispatch("setSelectedItem", item);
      console.log("delete item", item);
      console.log("item", item.actions.id);
      this.invoiceId = item.actions.id;
      this.isShowDeleteItemRow = true;
      // this.rowItem = item;
      this.$root.$emit("bv::show::modal", "delete-invoice-row-modal", button);
    },
    callInitFn() {
      this.showDeleteInvoiceModalKey = Number(new Date()) + 100;
      this.initFn();
      // this.isShowDeleteItemRow = false;
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole"
    }),
  }
};
</script>

<style scoped>
.unpaid-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-left: 20px;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 0;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}

.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  min-height: 240px;
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
  display: none;
}

.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

.table-element >>> .table-body td {
  vertical-align: middle;
}

.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}

.table-element >>> .table-body .main-text {
  font-size: 0.85rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}

.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}

/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

/* table per page selector */
.per-page-select {
  cursor: pointer;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0b9b7d;
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 25px;
  height: 20px;
  padding-right: 25px;
  /* box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15); */
}

.table-pagination >>> .custom-page-item button:hover {
  color: #0b9b7d;
  /* border-color: var(--secondary); */
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: #0b9b7d;
  /* border-color: var(--prime-gray); */
  background-color: transparent;
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--dark);
  border-color: transparent;
  background-color: transparent;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
}

/* insights card */
.insight-cards-row {
  padding: 36px;
  /* height: 150px; */
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  /* border: 1px solid var(--blue); */
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    /* height: 160px; */
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    /* height: 200px; */
  }
}
</style>
