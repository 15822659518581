<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 mx-0 my-4"
            no-gutters
          >
            <!-- left column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-4 py-2 py-lg-0"
              md="12"
              lg="2"
            >
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100"
                no-gutters
              >
                <h5
                  class="text-left text-dark font-primary font-weight-semi-bold"
                >
                  {{ customerName }}
                </h5>
              </b-row>
              <b-row
                class="d-flex align-items-start justify-content-center"
                cols="12"
              >
                <!-- title row  -->
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center py-2"
                  cols="12"
                  sm="6"
                  lg="12"
                >
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-prime-gray font-primary">
                      Contact Details
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ fullName }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ customerPhone }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ customerEmail }}
                    </span>
                  </b-row>
                </b-col>
                <!-- title row  -->
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center py-2"
                  cols="12"
                  sm="6"
                  lg="12"
                >
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-prime-gray font-primary">
                      Billing Address
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      No : {{ billingAddress.addressNum }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ billingAddress.addressStreet }},
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ billingAddress.addressCity }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ billingAddress.addressZip }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ billingAddress.addressProvince }}
                      {{ billingAddress.addressCountry }}
                    </span>
                  </b-row>
                </b-col>
                <!-- title row  -->
                <b-col
                  class="d-flex flex-column align-items-start justify-content-center py-2"
                  cols="12"
                >
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-prime-gray font-primary">
                      Shipping address
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.shippingName }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.shippingPhone }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100 mb-4"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.shippingEmail }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      No : {{ shippingAddress.addressNum }},
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.addressStreet }},
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.addressCity }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.addressZip }}
                    </span>
                  </b-row>
                  <b-row
                    class="d-flex flex-row align-items-start justify-content-start w-100"
                    no-gutters
                  >
                    <span class="text-left text-dark font-primary">
                      {{ shippingAddress.addressProvince }}
                      {{ shippingAddress.addressCountry }}
                    </span>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <!-- vertical divider (hidden on medium screens and below) -->
            <hr class="d-none d-lg-block h-100 border-left" />
            <!-- right column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-1 px-lg-0 py-2 py-lg-0"
              md="12"
              lg="9"
            >
              <b-row
                class="d-flex flex-row align-items-start justify-content-start w-100"
                no-gutters
              >
                <b-tabs
                  active-nav-item-class="font-weight-bold text-main-green"
                  class="invoice-tabs"
                  nav-class="tab-wrapper"
                  content-class="mt-3"
                >
                  <!-- table row -->
                  <b-tab title="Overview" title-link-class="tab-nav-link">
                    <CustomerOverview />
                  </b-tab>
                  <b-tab title="Invoices" title-link-class="tab-nav-link">
                    <CustomerInvoice />
                  </b-tab>
                </b-tabs>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import CustomerOverview from "@/components/Customers/CustomerDetails/CustomerOverview";
import CustomerInvoice from "@/components/Customers/CustomerDetails/CustomerInvoice";

// services
import { GetSingleCustomer ,GetSingleSupplier} from "@/services/customers.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
// import InvoicesUnpaidList from "@/components/Invoices/InvoicesUnpaidList";
// import InvoicesDraftList from "@/components/Invoices/InvoicesDraftList";

export default {
  name: "CustomerDetails",
  components: {
    HomeLayout,
    CustomerOverview,
    CustomerInvoice,
    // InvoicesAllList,
    // InvoicesDraftList,
    // InvoicesUnpaidList,cls
    //   DateFilter,
  },
  created() {
    // this.loadCurrentWorkspace();
    this.getCustomerDetails(this.$route.params.customerId);
  },
  data() {
    return {
      // customer details
      customerName: null,
      fullName: null,
      customerPhone: null,
      customerEmail: null,
      billingAddress: {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      },
      shippingAddress: {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      },
      shippingName: null,
      shippingPhone: null,
      shippingEmail: null,
      shippingStatus: "isBilling",
      addressProvinceOptions: ["Western", "Southern", "Northern", "Eastern"],
      addressCountryOptions: ["Sri Lanka"],
      isEditCustomer: null,
      customerNameError: {
        status: null,
        message: "",
      },

      // old
      startDate: "",
      endDate: "",
      formatted: "",
      selected: "",
      isShowDateFilterModal: false,
      rowItem: null,
      rowIndex: null,
      searchTable: null,
      isCountryManager: false,
      adminDetails: null,
      selectedCountryCode: null,
      filterDropDownShow: false,
      exportDropDownShow: false,
      isAnalyticsLoading: false,
      isLoading: false,
      isDateFilter: false,
      analytics: {
        amountOverdue: this.currency + " 300.00",
        monthOverdue: this.currency + " 2000.00",
        avgTime: "2 days",
        upcomingPayout: this.currency + " 500.00",
      },
      // table pagination controls
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100, 500],
      currency: localStorage.getItem("CURRENCY"),
    };
  },
  computed: {
    isSupplier() {
      return window.location.href.toString().includes("supplier");
    },
  },
  methods: {
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    async getCustomerDetails(customer_id) {
      if (this.isSupplier) {
        try {
          let { data } = await GetSingleSupplier(customer_id);
          console.log("get single", data);
          this.customerName = data.name;
          (this.fullName = data.full_name),
            (this.customerPhone = data.phone),
            (this.customerEmail = data.email),
            (this.billingAddress = {
              addressNum: data.billing_address.number,
              addressStreet: data.billing_address.street,
              addressCity: data.billing_address.city,
              addressZip: data.billing_address.zipcode,
              addressProvince: data.billing_address.province,
            }),
            (this.shippingAddress = {
              addressNum: data.shipping_address.number,
              addressStreet: data.shipping_address.street,
              addressCity: data.shipping_address.city,
              addressZip: data.shipping_address.zipcode,
              addressProvince: data.shipping_address.province,
            }),
            (this.shippingName = data.shipping_name),
            (this.shippingPhone = data.shipping_phone),
            (this.shippingEmail = data.shipping_email);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let { data } = await GetSingleCustomer(customer_id);
          console.log("get single", data);
          this.customerName = data.name;
          (this.fullName = data.full_name),
            (this.customerPhone = data.phone),
            (this.customerEmail = data.email),
            (this.billingAddress = {
              addressNum: data.billing_address.number,
              addressStreet: data.billing_address.street,
              addressCity: data.billing_address.city,
              addressZip: data.billing_address.zipcode,
              addressProvince: data.billing_address.province,
            }),
            (this.shippingAddress = {
              addressNum: data.shipping_address.number,
              addressStreet: data.shipping_address.street,
              addressCity: data.shipping_address.city,
              addressZip: data.shipping_address.zipcode,
              addressProvince: data.shipping_address.province,
            }),
            (this.shippingName = data.shipping_name),
            (this.shippingPhone = data.shipping_phone),
            (this.shippingEmail = data.shipping_email);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped>
/* insights card */
.insight-cards-row {
  padding: 25px;
  height: 150px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--dark);
  margin-bottom: 30px;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: #0b9b7d;
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 3px solid #0b9b7d;
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .customer-dropdown {
  height: 40px;
  min-width: 270px;
  max-width: 350px;
}

.table-card-row .status-dropdown {
  height: 40px;
  width: 200px;
  /* min-width: 270px;
  max-width: 350px; */
}

.table-card-row .date-picker {
  height: 40px;
  width: 150px;
  /* min-width: 150px;
  max-width: 200px; */
}

.table-card-row .filter-dropdown >>> .filter-dropdown-menu,
.table-card-row .export-dropdown >>> .export-dropdown-menu {
  min-width: 200px;
}

.table-card-row .export-dropdown >>> .export-dropdown-menu .inner-icon,
.table-card-row .filter-dropdown >>> .filter-dropdown-menu .inner-icon {
  width: 8px;
  height: 8px;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .export-dropdown
  >>> .export-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

.table-card-row .export-dropdown >>> .export-dropdown-button {
  padding: 6px 20px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .insight-cards-row {
    height: 230px;
  }

  .table-card-row .customer-dropdown,
  .table-card-row .status-dropdown {
    /* min-width: auto; */
    min-width: 200px;
    max-width: 350px;
  }

  .table-card-row .search-input-group {
    /* max-width: none; */
    width: 200px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .insight-cards-row {
    height: 400px;
  }

  .table-card-row .customer-dropdown,
  .table-card-row .status-dropdown {
    /* min-width: auto; */
    width: 350px;
  }

  .table-card-row .search-input-group {
    /* max-width: none; */
    width: 500px;
  }
}
</style>
