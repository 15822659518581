import axios from "axios";

export const GetAllVideos = async (params) => {
    try {
      return await axios.get("/howToVideos", { params });
    } catch (error) {
      return error;
    }
  };
  
  export const SearchVideos = ({title}) => {
    return axios.get(`/howToVideos/search`, {
      params: {title},
    });
  };