<template>
  <b-container
    class="d-flex flex-column align-items-start justify-content-start px-3 pr-lg-5 pl-lg-0 overflow-visible"
  >
    <!-- user insights row -->

    <!-- analytics -->
    <!-- <div class="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start shadow-sm border-1 insight-cards-row w-100 bg-light p-4">
        <div class="mr-5">
            <h6 class="text-left text-truncate text-small font-weight-bold text-info">
                Total unpaid
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
                LKR {{ Number(analytics.totalUnpaid).toLocaleString() }}
            </h5>
        </div>
        <div class="mr-5">
            <h6 class="text-left text-truncate text-small font-weight-bold text-info">
                Overdue
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
                LKR {{ Number(analytics.overdue).toLocaleString() }}
            </h5>
        </div>
        <div class="mr-5">
            <h6 class="text-left text-truncate text-small font-weight-bold text-info">
                LKR Not yet due
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
                LKR {{ Number(analytics.notYetDue).toLocaleString() }}
            </h5>
        </div>
        <div class="">
            <h6 class="text-left text-truncate text-small font-weight-bold text-info">
                Average time to get paid
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
                {{ analytics.avgTime }} <span class="text-gray-750 text-medium">days</span>
            </h5>
        </div>
    </div> -->

    <b-row
      class="d-flex flex-row align-items-stretch justify-content-center w-100"
      no-gutters
    >
      <b-card
        no-body
        class="position-relative overflow-hidden shadow-sm border-1 insight-cards-row w-100 bg-light"
      >
        <b-row
          class="d-flex flex-row align-items-center justify-content-start w-100"
          no-gutters
        >
          <!-- Total unpaid column -->
          <b-col
            class="d-flex flex-column align-items-start justify-content-center"
            cols="12"
            sm="6"
            lg="3"
          >
            <h6
              class="text-left text-truncate text-small font-weight-bold text-main-green"
            >
              Total unpaid
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
              {{ currency }}
              {{ parseFloat(total_unpaid).toFixed(2).toLocaleString() }}
            </h5>
          </b-col>
          <!-- Total overdue -->
          <b-col
            class="d-flex flex-column align-items-start justify-content-center mt-3 mt-sm-0"
            cols="12"
            sm="6"
            lg="3"
          >
            <h6
              class="text-left text-truncate text-small font-weight-bold text-main-green"
            >
              Overdue
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
              {{ currency }} {{ Number(overdue).toLocaleString() }}
            </h5>
          </b-col>

          <b-col
            class="d-flex flex-column align-items-start justify-content-center mt-3 mt-lg-0"
            cols="12"
            sm="6"
            lg="3"
          >
            <h6
              class="text-left text-truncate text-small font-weight-bold text-main-green"
            >
              {{ currency }} Not yet due
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
              {{ currency }} {{ Number(notYetDue).toLocaleString() }}
            </h5>
          </b-col>

          <b-col
            class="d-flex flex-column align-items-start justify-content-center mt-3 mt-lg-0"
            cols="12"
            sm="6"
            lg="3"
          >
            <h6
              class="text-left text-truncate text-small font-weight-bold text-main-green"
            >
              Average time to get paid
            </h6>
            <h5 class="text-left font-secondary font-weight-bold text-dark">
              {{ avgTime }}
              <span class="text-gray-750 text-medium">days</span>
            </h5>
          </b-col>
        </b-row>
      </b-card>
    </b-row>

    <b-row class="w-100 d-flex flex-row">
      <hr class="mt-4 mb-4 border-left w-100" />
    </b-row>
    <!-- invoices -->
    <b-row
      class="w-100 d-flex flex-row align-items-stretch justify-content-start"
    >
      <b-col>
        <h5 class="font-weight-bold mb-0">Invoices</h5>
      </b-col>
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-between w-100 my-4 ml-3"
        no-gutters
      >
        <!-- search input column -->
        <b-col
          class="d-flex flex-column align-items-start justify-content-center"
          cols="12"
          md="4"
        >
          <!-- table search input -->
          <b-input-group class="search-input-group">
            <b-form-input
              class="search-input border-0 bg-light"
              type="text"
              placeholder="Search invoice #"
              v-model="searchTable"
              @keyup.native.enter="searchFn"
            ></b-form-input>
            <b-input-group-append>
              <b-row
                class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light"
                no-gutters
              >
                <b-icon
                  class="text-main-green"
                  icon="search"
                  size="lg"
                  aria-label="Search"
                  font-scale="1.2"
                ></b-icon>
              </b-row>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          class="d-flex flex-column align-items-start justify-content-center mt-4 mt-md-0"
          cols="12"
          md="5"
          lg="4"
        >
          <b-button
            variant="main-green"
            class="d-flex flex-row align-items-center justify-content-center w-100 px-2 py-2 rounded-lg add-question-btn"
            to="/invoices/create"
            pill
            :disabled="getUserRole.role === 'User'"
          >
            <span class="d-inline ml-2">Create an Invoice</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="w-100">
        <b-col class="d-flex text-small align-items-center">
          <span class="text-gray-750 mr-2 ml-4">Filters</span>
          <div class="bg-light ellipse">
            <span class="text-main-green">{{ getFilterCount }}</span>
          </div>
        </b-col>
      </b-row>
      <!-- filter section -->
      <b-row class="w-100 mt-4 ml-1">
        <b-col cols="12" md="12" lg="4">
          <!-- <FilterSelect
            groupId="admin-type-group"
            id="admin-type-group-select"
            label="Account"
            v-model="filterBy.type"
            :options="filterOptions"
            form="admin-registration-form"
            class="mt-0 invoice-filter"
          ></FilterSelect> -->
          <b-dropdown
            id="dropdown-form"
            ref="dropdown"
            variant="light"
            class="w-100 m-0 py-0"
            toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
            menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
            left
            no-caret
            no-flip
            text="Button text via Prop"
          >
            <template v-slot:button-content>
              <b-button
                block
                id="customer-modal-btn"
                variant="transparent"
                class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
              >
                <span class="text-primary">{{
                  dataRange ? dataRange : "Date Range"
                }}</span>
                <b-icon
                  icon="chevron-down"
                  class="text-primary"
                  font-scale="0.9"
                ></b-icon>
              </b-button>
            </template>
            <div style="max-height: 300px; overflow-y: scroll">
              <b-dropdown-item
                @click="() => getDataRange(dataRange)"
                href="#"
                v-for="(dataRange, index) in dateRangeOptions"
                :key="index"
              >
                {{ dataRange }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-input-group>
            <b-form-input
              id="example-input"
              v-model="from"
              type="text"
              class="border border-main-green date-picker"
              placeholder="From"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="from"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                today-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-input-group>
            <b-form-input
              id="example-input"
              v-model="to"
              type="text"
              class="border border-main-green date-picker"
              placeholder="To"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="to"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                today-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="w-100 mt-4 ml-1 mb-2">
        <b-col cols="12" md="12" lg="4">
          <b-dropdown
            id="dropdown-form"
            ref="dropdown"
            variant="light"
            class="w-100 m-0 py-0"
            toggle-class="border border-dark-subtle bg-white rounded-lg border border-primary"
            menu-class="w-100 bg-white border border-dark-subtle border-5 py-0 "
            left
            no-caret
            no-flip
            text="Button text via Prop"
          >
            <template v-slot:button-content>
              <b-button
                block
                id="customer-modal-btn"
                variant="transparent"
                class="w-100 d-flex align-items-center justify-content-between rounded-lg p-0 m-0"
              >
                <span class="text-primary">{{
                  ageRange ? ageRange : "Select Age"
                }}</span>
                <b-icon
                  icon="chevron-down"
                  class="text-primary"
                  font-scale="0.9"
                ></b-icon>
              </b-button>
            </template>
            <div style="max-height: 300px; overflow-y: scroll">
              <b-dropdown-item
                @click="() => getAgeRange(ageRange)"
                href="#"
                v-for="(ageRange, index) in AgeRangeOptions"
                :key="index"
              >
                {{ ageRange }}
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </b-col>

        <b-col class="d-flex flex-row align-items-center justify-content-end">
          <b-row
            class="d-flex flex-row align-items-center justify-content-end w-100 px-3 py-1"
            no-gutters
            v-if="computedFilterValidations"
          >
            <!-- <b-button
                      class="text-white mx-2 my-2 my-lg-0 py-1 rounded-lg apply-filters-btn"
                      pill
                      @click="applyFiltersFn"
                    >
                      <span class="d-inline px-2">Apply</span>
                    </b-button> -->
            <b-link
              @click="clearFilterFn"
              class="text-left font-primary text-main-green font-weight-bold load-more-btn"
              >Clear all
            </b-link>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 table-container-row pl-2"
        no-gutters
      >
        <b-table
          ref="questionsTable"
          :bordered="false"
          :borderless="true"
          :hover="true"
          responsive
          selectable
          show-empty
          select-mode="single"
          class="table-element th-text"
          thead-class="table-header font-secondary"
          tbody-class="table-body"
          :busy="isLoading"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="invoiceHeaders"
          :items="invoiceItems"
        >
          <!-- table busy state -->
          <template #table-busy>
            <div class="text-center text-muted my-3">
              <b-spinner class="align-middle"></b-spinner>
              <span class="text-left mx-1">Loading...</span>
            </div>
          </template>
          <!-- table empty state -->
          <template #empty="scope">
            <b-row
              class="d-flex flex-row align-items-center justfiy-content-center py-5"
              no-gutters
            >
              <h6 class="text-center mb-0 mx-auto my-5">
                {{ scope.emptyText }}
              </h6>
            </b-row>
          </template>
          <!-- invoice Status  -->
          <template #cell(statusDetails)="data">
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0"
            >
              <div class="d-flex align-items-center text-nowrap">
                <div
                  :class="['ellipse', getStatusColor(data.value.status)]"
                ></div>
                <span class="main-text ml-2 text-capitalize">{{
                  data.value.status
                }}</span>
              </div>
            </b-col>
          </template>
          <!-- invoice Date -->
          <template #cell(dateDetails)="data">
            <span class="main-text mb-2 text-nowrap">{{
              data.value.date
            }}</span>
          </template>
          <!-- invoice Number  -->
          <template #cell(numberDetails)="data">
            <span class="main-text mb-2 text-nowrap">{{
              data.value.number
            }}</span>
          </template>
          <!-- invoice Total Amount  -->
          <template #cell(totalAmount)="data">
            <b-col class="d-flex flex-column justify-content-center px-0">
              <span class="main-text text-nowrap"
                >{{ currency }} {{ numberFormat(data.value.totalAmount) }}</span
              >
              <span class="main-text text-danger">{{
                data.value.overdue
              }}</span>
            </b-col>
          </template>
          <!-- invoice Amount Due  -->
          <template #cell(amountDetails)="data">
            <b-col class="d-flex flex-column justify-content-center px-0">
              <span class="main-text text-nowrap"
                >{{ currency }} {{ numberFormat(data.value.totalAmount) }}</span
              >
              <span class="main-text text-danger">{{
                data.value.overdue
              }}</span>
            </b-col>
          </template>
          <!-- actions column -->
          <template #cell(actions)="data">
            <b-dropdown
              id="dropdown-left"
              text="Left align"
              toggle-class="shadow-sm ml-auto border border-prime-gray bg-main-green more-btn rounded-circle"
              no-caret
              right
            >
              <template #button-content>
                <!-- <b-button
                      variant="outline-dark"
                      v-b-tooltip.hover.left
                      title="View More Actions"
                      class="shadow-sm ml-auto border border-prime-gray more-btn"
                      pill
                    > -->
                <b-icon
                  icon="chevron-down"
                  aria-label="View More Actions"
                  class="mx-auto more-icon"
                  size="lg"
                  font-scale="1.2"
                ></b-icon>
                <!-- </b-button> -->
              </template>
              <b-dropdown-item-button
                @click="editCustomerFn(data.value.id)"
                :disabled="getUserRole.role.includes('User')"
                >Edit</b-dropdown-item-button
              >
              <b-dropdown-item-button @click="viewInvoiceFn(data.value.id)"
                >View</b-dropdown-item-button
              >
              <b-dropdown-item-button
                @click="createInvoiceFn(data.value.id)"
                :disabled="getUserRole.role === 'User'"
                >Create Invoice</b-dropdown-item-button
              >
              <b-dropdown-item-button
                button-class="text-danger"
                @click="openDeleteInvoiceModal(data.item, $event.target)"
                :disabled="getUserRole.role.includes('User')"
                >Delete</b-dropdown-item-button
              >
            </b-dropdown>
          </template>

          <DeleteCustomerModal
            :isShow="isShowDeleteItemRow"
            @onClose="onCloseDeleteCustomerModal"
            :key="showDeleteCustomerModalKey"
            :itemContent="rowItem"
            @reloadFn="callInitFn"
          />
        </b-table>
      </b-row>
      <!-- table controls -->
      <!-- safari fix (flex-shrink-0) -->
      <b-row
        class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
        no-gutters
      >
        <!-- pages per page control -->
        <b-col
          class="d-flex flex-column align-items-center align-items-sm-start align-items-md-start align-items-xl-start justify-content-center py-2 mx-5 mb-3 py-md-1"
          cols="12"
          sm="4"
          lg="4"
          xl="3"
        >
          <b-form-group
            label="Per page"
            label-cols="12"
            label-cols-sm="6"
            label-align="left"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0 w-100"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              class="per-page-select border-1 border-main-green py-0"
              :options="pageOptions"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <!-- pagination controls -->
        <b-col
          class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="md"
            limit="4"
            pills
            class="table-pagination"
            page-class="custom-page-item"
            ellipsis-class="custom-page-item"
            first-class="custom-page-item"
            last-class="custom-page-item"
            next-class="custom-page-item"
            prev-class="custom-page-item"
          >
            <template #first-text>
              <b-icon
                class="text-main-green"
                icon="chevron-left"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
            <template #prev-text>
              <b-icon
                class="text-main-green"
                icon="chevron-double-left"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
            <template #next-text>
              <b-icon
                class="text-main-green"
                icon="chevron-double-right"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
            <template #last-text>
              <b-icon
                class="text-main-green"
                icon="chevron-right"
                size="sm"
                aria-label="Plus"
              ></b-icon>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-row>
  </b-container>
</template>

<script>
// import FilterSelect from "@/components/Form/FilterSelect";
import DeleteCustomerModal from "@/components/Customers/modals/DeleteCustomerModal";
import {
  CustomerSummery,
  GetAllInvoices,
  // GetUnpaidInvoices,
} from "@/services/invoice.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { formatNumbers } from "@/util/formatNumbers.js";
import { mapGetters } from "vuex";

export default {
  name: "CustomerInvoice",
  components: {
    DeleteCustomerModal,
    // FilterSelect,
  },
  created() {
    // this.loadCurrentWorkspace();
    this.clearFilterFn();
    this.loadCustomerSum(this.$route.params.customerId);
    this.fetchUserInvoices(this.$route.params.customerId);
  },
  data() {
    return {
      total_unpaid: 0.0,
      paid_last_12: 0.0,
      overdue:0.0,
      notYetDue:0.0,
      avgTime:0.0,
      isFiltersApplied: false,
      dataRange: null,
      dateRangeOptions: [
        "This financial year",
        "Last financial year",
        "Last year Q1",
        "Last year Q2",
        "Last year Q3",
        "Last year Q4",
      ],
      ageRange: null,
      AgeRangeOptions: [
        "0 - 30 Days",
        "31 - 60 Days",
        "61 - 90 Days",
        "91 - 120 Days",
        "120+ Days",
      ],
      analytics: {
        totalUnpaid: 4000,
        overdue: 2000,
        notYetDue: 1000,
        avgTime: 2,
      },
      filterBy: {
        type: "op1",
        from: "",
        to: "",
        dataRange: "",
        ageRange: "",
      },
      from: null,
      to: null,
      filterOptions: ["", ""],
      rowIndex: null,
      filterDropDownShow: false,
      selectedRegion: {
        title: null,
        code: null,
        flagCode: null,
        languageCode: null,
      },
      isRegionFilter: false,
      searchTable: null,
      isLoading: false,
      invoiceHeaders: [
        {
          key: "statusDetails",
          label: "Status",
        },
        {
          key: "dateDetails",
          label: "Date",
          sortable: true,
        },
        {
          key: "numberDetails",
          label: "Number",
          class: "text-center",
        },
        {
          key: "totalAmount",
          label: "Total Amount",
          class: "text-center text-nowrap",
        },
        {
          key: "amountDetails",
          label: "Amount Due",
          class: "text-center text-nowrap",
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      invoiceItems: [],
      // table pagination controls
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      // delete modal
      rowItem: null,
      isShowDeleteItemRow: false,
      showDeleteCustomerModalKey: Number(new Date()) + 100,
      currency: localStorage.getItem("CURRENCY"),
    };
  },
  methods: {
    numberFormat(val) {
      return formatNumbers(val);
    },
    async loadCustomerSum(customer) {
      console.log("=====================================");
      let { data } = await CustomerSummery(customer);
      console.log(data);
      this.total_unpaid = data.total_unpaid ? data.total_unpaid : 0.0;
      this.paid_last_12 = data.paid_last_12 ? data.paid_last_12 : 0.0;
      this.overdue = data.over_due ? data.over_due : 0.0;
      this.notYetDue = data.not_yet_due ? data.not_yet_due : 0.0;
      this.avgTime = data.avarage_time_to_get_paid ? data.avarage_time_to_get_paid : 0.0;
    },
    getStatusColor(status) {
     
      if (status === "Draft") {
        return "bg-gray-750 text-prime-gray";
      } else if (status === "Unsent") {
        return "bg-gray-750 text-prime-gray";
      } else if (status === "Sent") {
        return "bg-info";
      } else if (status === "Overdue") {
        return "bg-danger";
      } else if (status === "Paid") {
        return "bg-success";
      }
      else if (status === "Partial") {
        return "bg-warning";
      }
      return "bg-gray-750";
    },
    editCustomerFn(id) {
      this.$router.push("/invoices/edit/" + id);
    },
    viewInvoiceFn(id) {
      this.$router.push("/invoices/" + id);
    },
    createInvoiceFn() {
      this.$router.push("/invoices/create");
    },
    // searchFn() {
    //   if (this.searchTable && this.searchTable.length > 0) {
    //     this.SearchInvoices();
    //   }
    // },
    // async SearchInvoices(){
    //   try {
    //     this.invoiceItems = [];
    //     this.isLoading = true;
    //     let { data } = await SearchInvoice({ text: this.searchTable });
    //     console.log("invoices", data);
    //     this.invoiceItems = data.results.results.map((x) => {
    //       return {
    //          statusDetails: {
    //           id: x._id,
    //           status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
    //           dueDate: x.due_date.split("T")[0],
    //         },
    //         dateDetails: {
    //           id: x._id,
    //           date: x.issued_date.split("T")[0],
    //         },
    //         numberDetails: {
    //           id: x._id,
    //           number: x.invoice_number,
    //         },
    //         totalAmount: {
    //           id: x._id,
    //           totalAmount: x.total_amount,
    //         },
    //         amountDetails: {
    //           id: x._id,
    //           amountDue: x.amount_due,
    //           totalAmount: x.total_amount,
    //         },
    //         actions: {
    //           id: x._id,
    //           status: x.status,
    //           dueDate: x.due_date.split("T")[0],
    //         },
    //       };
    //     });
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.isLoading = false;
    //     console.log(error);
    //   }
    // },
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    openDeleteInvoiceModal(data_item) {
      this.rowItem = data_item;
      this.isShowDeleteItemRow = true;
    },
    onCloseDeleteCustomerModal() {
      this.isShowDeleteItemRow = false;
    },
    async fetchUserInvoices(customer_id) {
      console.log(
        "🚀 ~ file: CustomerInvoice.vue:809 ~ fetchUserInvoices ~ this.filterBy:",
        this.filterBy
      );
      try {
        this.isLoading = true;
        let { data } = await GetAllInvoices(
          customer_id,
          this.searchTable,
          undefined,
          this.filterBy.from,
          this.filterBy.to,
          this.filterBy.dataRange,
          undefined,
          undefined,
          undefined,
          true
        );
        const formatDate = (date) => {
          const d = new Date(date);
          return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        };
        this.invoiceItems = data.results.results.map((x) => {
          return {
            // invoiceStatus: x.status.charAt(0).toUpperCase() + x.status.slice(1),
            // invoiceDate: x.issued_date.split("T")[0],
            // invoiceNumber: x.invoice_number,
            // invoiceTotalAmount: x.total_amount,
            // invoiceAmountDue: x.amount_due,

            statusDetails: {
              id: x._id,
              status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
              dueDate: x.due_date.split("T")[0],
            },
            dateDetails: {
              id: x._id,
              date: formatDate(x.issued_date.split("T")[0]),
            },
            numberDetails: {
              id: x._id,
              number: x.invoice_number,
            },
            // customerDetails: {
            //     id: x._id,
            //     customerName: x.customer_name,
            // },
            totalAmount: {
              id: x._id,
              totalAmount: x.total_amount,
            },
            amountDetails: {
              id: x._id,
              amountDue: x.amount_due,
              totalAmount: x.total_amount,
            },
            actions: {
              id: x._id,
              status: x.status,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async fetchUserUnpaidInvoices(customer_id) {
      try {
        this.isLoading = true;
        // let { data } = await GetUnpaidInvoices(
          let { data } = await GetAllInvoices(
          customer_id,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          this.filterBy.ageRange,
          true
        );
        const formatDate = (date) => {
          const d = new Date(date);
          return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        };
        this.invoiceItems = data.results.results.map((x) => {
          return {
            statusDetails: {
              id: x._id,
              status: x.status.charAt(0).toUpperCase() + x.status.slice(1),
              dueDate: x.due_date.split("T")[0],
            },
            dateDetails: {
              id: x._id,
              date: formatDate(x.issued_date.split("T")[0]),
            },
            numberDetails: {
              id: x._id,
              number: x.invoice_number,
            },
            // customerDetails: {
            //     id: x._id,
            //     customerName: x.customer_name,
            // },
            totalAmount: {
              id: x._id,
              totalAmount: x.total_amount,
            },
            amountDetails: {
              id: x._id,
              amountDue: x.amount_due,
              totalAmount: x.total_amount,
            },
            actions: {
              id: x._id,
              status: x.status,
              dueDate: x.due_date.split("T")[0],
            },
          };
        });
        this.isLoading = false;
        console.log("draft invoice items", this.invoiceItems);
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    getDataRange(val) {
      this.isFiltersApplied = true;
      console.log(
        "🚀 ~ file: Transactions.vue:1110 ~ getDataRange ~ val:",
        val
      );
      this.dateRange = val;
      this.dataRange = val;
      this.filterBy.dataRange = val;
      this.fetchUserInvoices(this.$route.params.customerId);
    },
    getAgeRange(val) {
      this.isFiltersApplied = true;
      console.log("🚀 ~ file: Transactions.vue:1110 ~ getAgeRange ~ val:", val);
      this.ageRange = val;
      console.log(
        "🚀 ~ file: CustomerInvoice.vue:941 ~ getAgeRange ~ this.filterBy:",
        this.filterBy
      );
      this.filterBy.ageRange = val;
      this.fetchUserUnpaidInvoices(this.$route.params.customerId);
    },
    clearFilterFn() {
      this.selectedCustomer = null;
      this.searchTable = null;
      this.selectedStatus = null;
      this.from = null;
      this.to = null;
      this.dataRange = null;
      this.ageRange = null;
      this.dateRange = null;
      this.filterBy.dataRange = null;
      this.fetchUserInvoices(this.$route.params.customerId);
    },
  },
  watch: {
    searchTable() {
      this.fetchUserInvoices(this.$route.params.customerId);
    },
    from(val) {
      this.filterBy.from = val;
      this.fetchUserInvoices(this.$route.params.customerId);
    },
    to(val) {
      this.filterBy.to = val;
      this.fetchUserInvoices(this.$route.params.customerId);
    },
  },
  computed: {
    ...mapGetters({
      getUserRole: "auth/getUserRole",
    }),
    getFilterCount() {
      let count = 0;
      if (this.searchTable) {
        count++;
      }
      if (this.filterBy.from) {
        count++;
      }
      if (this.filterBy.to) {
        count++;
      }
      return count;
    },
    computedFilterValidations() {
      if (
        this.selectedCustomer ||
        this.searchTable ||
        this.selectedStatus ||
        this.from ||
        this.to ||
        this.dataRange ||
        this.ageRange
      ) {
        return true;
      }
      if (this.isFiltersApplied) this.clearFilterFn();
      return false;
    },
  },
};
</script>

<style scoped>
.add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}
.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}
.insight-cards-row {
  padding: 40px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  /* border: 1px solid var(--blue); */
  margin-top: 25px;
  margin-bottom: 25px;
}

.th-text {
  font-size: 0.92rem;
}

.main-text {
  font-size: 0.85rem;
}

/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0b9b7d;
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 25px;
  height: 20px;
  padding-right: 25px;
  /* box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15); */
}

.table-pagination >>> .custom-page-item button:hover {
  color: #0b9b7d;
  /* border-color: var(--secondary); */
  background-color: var(--light);
}

.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: #0b9b7d;
  /* border-color: var(--prime-gray); */
  background-color: transparent;
}

.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--dark);
  border-color: transparent;
  background-color: transparent;
}
.invoice-filter {
}

.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
  /* border-radius: 10px; */
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
  border-radius: 5px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}
</style>
