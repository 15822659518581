import { render, staticRenderFns } from "./CustomerOverview.vue?vue&type=template&id=748ce233&scoped=true"
import script from "./CustomerOverview.vue?vue&type=script&lang=js"
export * from "./CustomerOverview.vue?vue&type=script&lang=js"
import style0 from "./CustomerOverview.vue?vue&type=style&index=0&id=748ce233&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "748ce233",
  null
  
)

export default component.exports